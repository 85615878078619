<template>
  <v-container>
    <v-treeview :items="permissions" item-key="SystemPermissionID" item-text="group" open-all>
      <template v-slot:append="{ item }">
        <template v-if="item.keywords.length === 1 && item.keywords[0].key == null">
          <v-checkbox
            v-model="item.keywords[0].checked"
            :disabled="item.keywords[0].disabled || !editMode"
            @change="updateCheckbox($event, item, item.keywords[0])"
            v-bind:color="item.keywords[0].checked ? 'primary lighten-3' : 'white'"
            :indeterminate="item.keywords[0].childrenChecked && !item.keywords[0].checked"
          />
        </template>
        <template v-else>
          <template v-for="keyword in item.keywords.filter((element) => element.key != null)">
            <v-tooltip left :key="keyword.key" color="primary base">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <mex-btn
                    :class="[keyword.checked ? 'checkedFormat' : [keyword.childrenChecked ? 'checkedChildrenFormat' : 'uncheckedFormat']]"
                    tile
                    :disabled="keyword.disabled || !editMode"
                    v-bind:content="getPermissionIcon(keyword.key) ? '' : keyword.key"
                    outlined
                    @click="updateButton(item, keyword)"
                    :icon="getPermissionIcon(keyword.key)"
                  />
                </span>
              </template>
              <span>{{ keyword.key }}</span>
            </v-tooltip>
          </template>
        </template>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
import permissionIcons from '../../config/permissionIcons.config';

export default {
  name: 'PermissionTreeview.vue',
  props: {
    permissions: {
      type: Array,
      default: () => {
        return [];
      },
      description: 'permissions for the treeview',
    },
    selectedPermissions: {
      type: Array,
      default: () => {
        return [];
      },
      description: 'ids of the permissions that are selected',
    },
    editMode: {
      type: Boolean,
      default: () => {
        return false;
      },
      description: 'decides if the permissions are editable',
    },
  },
  computed: {
    getSelectedPermissions() {
      return this.selectedPermissions;
    },
  },
  data() {
    return {
      newlySelectedPermissions: [],
      newlyDeselectedPermissions: [],
    };
  },
  created() {
    this.checkForChildrenChecked(this.permissions);
  },
  methods: {
    updateButton(item, keyword) {
      if (keyword.checked) {
        this.deselectPermissions(item, keyword);
      } else {
        this.selectPermissions(item, keyword);
      }
    },
    updateCheckbox(newValue, item, keyword) {
      if (newValue) {
        this.selectPermissions(item, keyword);
      } else {
        this.deselectPermissions(item, keyword);
      }
    },
    selectPermissions(item, keyword) {
      this.newlySelectedPermissions = [];
      keyword.checked = true;
      this.newlySelectedPermissions.push(keyword.SystemPermissionID);
      if (keyword.key) {
        this.selectAllNeededKeys(item, this.getPermissionNeededKeys(keyword.key));
      }
      if (item.children.length !== 0) this.selectAllChildren(item.children, keyword.key);
      const originArray = keyword.origin.split('_');
      if (originArray.length >= 2) this.selectParents(this.permissions, originArray[originArray.length - 2], keyword.key);
      this.$emit('permissionSelectionChanged', this.getSelectedPermissions.concat(this.newlySelectedPermissions));
      this.checkForChildrenChecked(this.permissions);
    },
    selectParents(permissions, origin, key) {
      permissions.forEach((permission) => {
        if (origin === permission.group) {
          permission.keywords.forEach((keyword) => {
            if (keyword.key === key) {
              if (this.checkIfChildrenAreAllSelected(permission.children, key)) {
                keyword.checked = true;
                this.newlySelectedPermissions.push(keyword.SystemPermissionID);
                this.selectAllNeededKeys(permission, this.getPermissionNeededKeys(keyword.key));
                const originArray = keyword.origin.split('_');
                if (originArray.length >= 2) this.selectParents(this.permissions, originArray[originArray.length - 2], keyword.key);
              }
            }
          });
        } else {
          this.selectParents(permission.children, origin, key);
        }
      });
    },
    checkIfChildrenAreAllSelected(children, key) {
      for (let i = 0; i < children.length; i++) {
        for (let j = 0; j < children[i].keywords.length; j++) {
          if (children[i].keywords[j].key === key && !children[i].keywords[j].checked) return false;
        }
      }
      return true;
    },
    selectAllNeededKeys(item, keys) {
      item.keywords.forEach((keyword) => {
        if (keys.includes(keyword.key) && !keyword.checked) {
          keyword.checked = true;
          this.newlySelectedPermissions.push(keyword.SystemPermissionID);
          this.checkIfChildrenIsChecked(this.permissions, keyword.key);
          if (keyword.key) {
            const neededKeys = this.getPermissionNeededKeys(keyword.key);
            if (neededKeys.length !== 0) {
              this.selectAllNeededKeys(item, neededKeys);
            }
          }
        }
      });
    },
    selectAllChildren(items, key) {
      items.forEach((item) => {
        item.keywords.forEach((keyword) => {
          if (keyword.key === key && !keyword.checked) {
            keyword.checked = true;
            this.newlySelectedPermissions.push(keyword.SystemPermissionID);
            if (keyword.key) {
              this.selectAllNeededKeys(item, this.getPermissionNeededKeys(keyword.key));
            }
          }
        });
        this.selectAllChildren(item.children, key);
      });
    },
    deselectPermissions(item, keyword) {
      this.newlyDeselectedPermissions = [];
      keyword.checked = false;
      this.newlyDeselectedPermissions.push(keyword.SystemPermissionID);
      if (item.children.length !== 0) this.deselectAllChildren(item.children, keyword.key);
      this.deselectAllNeededKeys(item, this.getWherePermissionKeysNeeded(keyword.key));
      let originArray = keyword.origin.split('_');
      originArray = originArray.slice(0, originArray.length - 1);
      if (originArray.length !== 0) this.deselectParents(this.permissions, originArray, keyword.key);
      this.$emit(
        'permissionSelectionChanged',
        this.getSelectedPermissions.filter((permission) => !this.newlyDeselectedPermissions.includes(permission)),
      );
      this.checkForChildrenChecked(this.permissions);
    },
    deselectParents(permissions, originArray, key) {
      permissions.forEach((permission) => {
        if (originArray.includes(permission.group)) {
          if (permission.children.length !== 0) this.deselectParents(permission.children, originArray, key);
          permission.keywords.forEach((keyword) => {
            if (keyword.key === key) {
              keyword.checked = false;
              this.newlyDeselectedPermissions.push(keyword.SystemPermissionID);
              this.deselectAllNeededKeys(permission, this.getWherePermissionKeysNeeded(keyword.key));
            }
          });
        }
      });
    },
    deselectAllNeededKeys(item, keys) {
      item.keywords.forEach((keyword) => {
        if (keys.includes(keyword.key)) {
          keyword.checked = false;
          this.newlyDeselectedPermissions.push(keyword.SystemPermissionID);
          const whereKeysNeeded = this.getWherePermissionKeysNeeded(keyword.key);
          if (whereKeysNeeded.length !== 0) {
            this.deselectAllNeededKeys(item, whereKeysNeeded);
          }
        }
      });
    },
    deselectAllChildren(items, key) {
      items.forEach((item) => {
        if (item.children.length !== 0) this.deselectAllChildren(item.children, key);
        item.keywords.forEach((keyword) => {
          if (keyword.key === key) {
            keyword.checked = false;
            this.newlyDeselectedPermissions.push(keyword.SystemPermissionID);
            this.deselectAllNeededKeys(item, this.getWherePermissionKeysNeeded(keyword.key));
          }
        });
      });
    },
    getPermissionIcon(key) {
      for (let i = 0; i < permissionIcons.icons.length; i++) {
        if (permissionIcons.icons[i].key === key) {
          return permissionIcons.icons[i].icon;
        }
      }
      return null;
    },
    getPermissionNeededKeys(key) {
      for (let i = 0; i < permissionIcons.icons.length; i++) {
        if (permissionIcons.icons[i].key === key) {
          return permissionIcons.icons[i].neededKeys;
        }
      }
      return null;
    },
    getWherePermissionKeysNeeded(key) {
      const keys = [];
      permissionIcons.icons.forEach((icon) => {
        if (icon.neededKeys.includes(key)) {
          keys.push(icon.key);
        }
      });
      return keys;
    },
    checkForChildrenChecked(permissions) {
      permissions.forEach((permission) => {
        this.checkForChildrenChecked(permission.children);
        permission.keywords.forEach((keyword) => {
          if (this.checkIfChildrenIsChecked(permission.children, keyword.key)) {
            keyword.childrenChecked = true;
          } else {
            keyword.childrenChecked = false;
          }
        });
      });
    },
    checkIfChildrenIsChecked(children, key) {
      for (let i = 0; i < children.length; i++) {
        for (let j = 0; j < children[i].keywords.length; j++) {
          if (children[i].keywords[j].key === key && (children[i].keywords[j].checked || children[i].keywords[j].childrenChecked)) return true;
        }
      }
      return false;
    },

  },
};
</script>

<style scoped>
.v-checkbox:disabled {

}
.checkedFormat {
  color: var(--v-primary-darken3);
  background-color: var(--v-primary-lighten3);
}
.checkedFormat:disabled {
  background-color: var(--v-primary-base) !important;
}
.checkedChildrenFormat {
  color: var(--v-primary-darken3);
  background: repeating-linear-gradient(
    45deg,
    var(--v-primary-lighten3),
    var(--v-primary-lighten3) 10px,
    var(--v-primary-base) 10px,
    var(--v-primary-base) 20px
  );
}
.checkedChildrenFormat:disabled {
  background: repeating-linear-gradient(
    45deg,
    var(--v-primary-lighten1),
    var(--v-primary-lighten1) 10px,
    var(--v-primary-base) 10px,
    var(--v-primary-base) 20px
  );
}
.uncheckedFormat {
  color: var(--v-primary-darken3) !important;
  background-color: #FAFAFA;
}
.uncheckedFormat:disabled {
  color: var(--v-primary-darken3);
  background-color: #757575;
}
</style>

import { render, staticRenderFns } from "./PermissionTreeview.vue?vue&type=template&id=360102a2&scoped=true&"
import script from "./PermissionTreeview.vue?vue&type=script&lang=js&"
export * from "./PermissionTreeview.vue?vue&type=script&lang=js&"
import style0 from "./PermissionTreeview.vue?vue&type=style&index=0&id=360102a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360102a2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCheckbox,VContainer,VTooltip,VTreeview})

/**
 * Intercepts the keyboard shortcuts Ctrl+E and Ctrl+S to switch in and out of the editMode
 * and to save the changes made while in editMode
 *
 * @param {object} _this
 * @param {object} _document
 * @param {string} unboundListenerProp
 * @param {string} boundListenerProp
 * @param {string} editModeProp
 * @param {string} discardProp
 * @param {string} saveProp
 */
function editModeAndSaveShortCut(_this, _document, unboundListenerProp, boundListenerProp, editModeProp, discardProp, saveProp) {
  _this[unboundListenerProp] = function editModeAndSaveShortCutHandle(e) {
    if (e.key === 'e' && (e.ctrlKey || e.metaKey) && !_this[editModeProp]) {
      e.preventDefault();
      _this[editModeProp] = true;
    } else if (e.key === 'e' && (e.ctrlKey || e.metaKey) && _this[editModeProp]) {
      e.preventDefault();
      _this[discardProp]();
    }
    if (e.key === 's' && (e.ctrlKey || e.metaKey) && _this[editModeProp]) {
      e.preventDefault();
      _this[saveProp]();
    }
  };
  _this[boundListenerProp] = _this[unboundListenerProp].bind(_this);
  _document.addEventListener('keydown', _this[boundListenerProp]);
}

module.exports = {
  editModeAndSaveShortCut,
};

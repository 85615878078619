module.exports = {
  icons: [
    { key: "read", icon: "mdi-glasses", neededKeys: [] },
    { key: "write", icon: "mdi-pencil", neededKeys: ["read"] },
    { key: "create", icon: "mdi-note-plus-outline", neededKeys: ["view"] },
    { key: "edit", icon: "mdi-clipboard-edit-outline", neededKeys: ["create"] },
    { key: "view", icon: "mdi-glasses", neededKeys: [] },
    { key: "enable", icon: "mdi-headset", neededKeys: [] },
    { key: "disable", icon: "mdi-headset-off", neededKeys: [] },
    { key: "delete", icon: "mdi-trash-can-outline", neededKeys: ["edit"] }
  ],
}

import httpClient from "../httpClient/httpClient";

const SystemRolesService = {
  getRoles() {
    return httpClient.get("api/get-all-roles", {});
  },
  deleteRole(roleID) {
    return httpClient.post("api/delete-role", {
      roleID
    });
  },
  getRoleByRoleID(roleID) {
    return httpClient.get("api/get-role-by-roleID", {
      params: {
        roleID
      }
    });
  },
  getRolesList() {
    return httpClient.get("api/get-roles-list", {});
  },
  getPermissionsByRoleID(roleID) {
    return httpClient.get("api/get-permissions-by-roleID", {
      params: {
        roleID
      }
    });
  },
  updateRole(roleID, name, description, parentRoleID, SystemPermissionIDs) {
    return httpClient.post("api/edit-role", {
      roleID,
      name,
      description,
      parentRoleID,
      SystemPermissionIDs
    });
  },
  addRole(name, description, parentRoleID, SystemPermissionIDs) {
    return httpClient.post("api/add-role", {
      name,
      description,
      parentRoleID,
      SystemPermissionIDs
    });
  }
};

export default SystemRolesService;

<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="roleViewLoading" :spinnerText="getRoleLoadingSpinnerText" />
    <template v-else>
      <validation-observer ref="observer" v-slot="{ invalid }" @submit.prevent="saveChanges">
        <v-row justify="center">
          <v-col cols="12">
            <mex-heading content="System Role"></mex-heading>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <mex-sheet color="background" rounded sheetPadding="2">
              <v-row>
                <v-col cols="4"></v-col>
                <v-col justify="center" cols="4">
                  <v-autocomplete
                    v-model="selectedSystemRole"
                    :items="systemRoles"
                    hide-details
                    item-text="name"
                    item-value="SystemRoleID"
                    outlined
                    dense
                    @change="selectionChanged"
                  />
                </v-col>
                <v-col justify="end" cols="4">
                  <v-row v-if="selectedSystemRole && !roleViewLoading" justify="end">
                    <v-slide-x-transition>
                      <v-col v-if="editMode" cols="1">
                        <mex-btn
                          icon="mdi-content-save"
                          iconOnly
                          @click="saveChanges"
                          :disabled="invalid"
                        />
                      </v-col>
                    </v-slide-x-transition>
                    <v-slide-x-transition>
                      <v-col v-if="editMode" cols="1">
                        <mex-btn icon="mdi-undo" iconOnly @click="getRoleViewData" />
                      </v-col>
                    </v-slide-x-transition>
                    <v-col cols="1">
                      <mex-btn
                        v-if="writeRoles && !defaultRole"
                        :color="editMode ? 'primary' : ''"
                        icon="mdi-pencil"
                        iconOnly
                        @click="editMode = true"
                      />
                    </v-col>
                    <v-col align-self="center" cols="1">
                      <mex-btn icon="mdi-refresh" iconOnly @click="getRoleViewData" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
        <mex-sperm-spinner v-if="roleDataLoading" :spinnerText="getRoleLoadingSpinnerText" />
        <template v-else>
          <v-row justify="center">
            <v-col cols="12">
              <mex-card v-if="selectedSystemRole" icon="mdi-account-multiple">
                <v-row>
                  <v-col cols="4">
                    <v-row justify="center">
                      <v-col cols="8">
                        <validation-provider v-slot="{ errors }" name="Role Name" rules="required">
                          <v-text-field
                            v-model="roleName"
                            :error-messages="errors"
                            :readonly="!editMode"
                            label="Role Name"
                            outlined
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="8">
                        <v-textarea
                          v-model="description"
                          label="Description"
                          :readonly="!editMode"
                          outlined
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-divider vertical></v-divider>
                  </v-col>
                  <v-col cols="7">
                    <v-row>
                      <validation-provider v-slot="{ errors }" name="Parent Role" rules="required">
                        <v-autocomplete
                          v-if="parentRoleID !== -1"
                          v-model="parentRoleID"
                          :error-messages="errors"
                          :items="getRolesList"
                          item-text="name"
                          item-value="SystemRoleID"
                          outlined
                          label="Parent Role"
                          :readonly="!editMode"
                          @change="parentRoleSelectionChanged"
                        />
                      </validation-provider>
                    </v-row>
                    <v-row>
                      <permission-treeview
                        :permissions="permissions"
                        :selectedPermissions="selectedPermissions"
                        :editMode="editMode"
                        @permissionSelectionChanged="updateMyRolePermissionArray"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </mex-card>
            </v-col>
          </v-row>
        </template>
      </validation-observer>
    </template>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PermissionTreeview from "../../components/LicSrvComponents/PermissionTreeview.vue";
import SystemPermissionsService from "../../services/systemPermissions.service";
import SystemRolesService from "../../services/systemRoles.service";
import requiredPermissions from "../../requiredPermissions";
import keyListenerManger from "../../functions/keyListenerManager";
import SaveRequest from "../../components/LicSrvComponents/SaveRequest";

export default {
  name: "EditUser.vue",
  components: {
    SaveRequest,
    PermissionTreeview
  },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    writeRoles() {
      return this.getUserPermissions.includes("write_System_User Role Administration_Role");
    },
    getRoleLoadingSpinnerText() {
      return this.roleViewLoadingText;
    },
    getRolesList() {
      return this.systemRoles.filter(role => role.SystemRoleID !== this.selectedSystemRole);
    }
  },
  data() {
    return {
      permissions: [],
      selectedPermissions: [],
      editMode: false,
      defaultRole: true,
      roleName: "",
      description: "",
      parentRoleID: null,
      parentRolesPermissions: [],
      hasPermission: false,
      roleViewLoading: false,
      roleDataLoading: false,
      roleViewLoadingText: "",
      showSaveRequest: false,
      systemRoles: [],
      selectedSystemRole: null,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null
    };
  },
  methods: {
    fetchAllSystemRoles() {
      SystemRolesService.getRolesList().then(response => {
        this.systemRoles = response.data.roles;
        this.roleViewLoading = false;
      });
    },
    async saveChanges() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        this.$toast.error("Invalid parameters");
      } else {
        SystemRolesService.updateRole(
          this.selectedSystemRole,
          this.roleName,
          this.description,
          this.parentRoleID,
          this.selectedPermissions
        )
          .then(response => {
            this.editMode = false;
            this.$toast.success("Update successful");
            this.$router.push({
              name: "ParamsReload",
              params: { name: "RoleView", reloadParams: { id: response.data.editedSystemRoleID } }
            });
          })
          .catch(err => {
            this.$toast.error(`Error while updating role: ${err.response.data.message}`);
          });
      }
    },
    getRoleViewData() {
      this.editMode = false;
      this.roleDataLoading = true;
      this.roleViewLoadingText = "loading role data";
      // --------------------------- GET THE ROLE DATA ---------------------
      SystemRolesService.getRoleByRoleID(this.selectedSystemRole)
        .then(roleResponse => {
          this.roleName = roleResponse.data.role.name;
          this.description = roleResponse.data.role.description;
          this.defaultRole = roleResponse.data.role.default;
          this.parentRoleID = roleResponse.data.role.parentRoleID;
          this.roleViewLoadingText = "loading permissions";
          // ------------------------------- GET ALL PERMISSIONS ------------------
          SystemPermissionsService.getAllPermissions()
            .then(allPermissionsResponse => {
              this.permissions = this.addValuesToPermissions(
                allPermissionsResponse.data.permissions
              );
              this.roleViewLoadingText = "loading role permissions";
              // ----------------------------------- GET ROLE PERMISSIONS ---------------------
              SystemRolesService.getPermissionsByRoleID(this.selectedSystemRole)
                .then(rolePermissionsResponse => {
                  this.selectedPermissions = rolePermissionsResponse.data.permissions;
                  this.checkForSelectedPermissions(this.permissions);
                  if (this.parentRoleID !== -1) {
                    this.roleViewLoadingText = "loading parent role permissions";
                    // --------------------------------- GET PARENT ROLE PERMISSIONS --------------------
                    SystemRolesService.getPermissionsByRoleID(this.parentRoleID)
                      .then(parentPermissionsResponse => {
                        this.parentRolesPermissions = parentPermissionsResponse.data.permissions;
                        this.disablePermissions(this.permissions);
                      })
                      .catch(err => {
                        this.roleDataLoading = false;
                        this.$toast.error(err.response.data.message);
                      });
                  }
                  this.roleDataLoading = false;
                })
                .catch(err => {
                  this.roleDataLoading = false;
                  this.$toast.error(err.response.data.message);
                });
            })
            .catch(err => {
              this.roleDataLoading = false;
              this.$toast.error(err.response.data.message);
            });
        })
        .catch(err => {
          this.roleDataLoading = false;
          this.$toast.error(err.response.data.message);
        });
    },
    updateMyRolePermissionArray(value) {
      this.selectedPermissions = value;
    },
    checkForSelectedPermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].keywords.forEach(keyword => {
          if (this.selectedPermissions.includes(keyword.SystemPermissionID)) {
            keyword.checked = true;
          }
        });
        if (permissions[i].children.length !== 0) {
          this.checkForSelectedPermissions(permissions[i].children);
        }
      }
    },
    disablePermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].keywords.forEach(keyword => {
          if (!this.parentRolesPermissions.includes(keyword.SystemPermissionID)) {
            keyword.checked = false;
            keyword.disabled = true;
          } else {
            keyword.disabled = false;
          }
        });
        if (permissions[i].children.length !== 0) {
          this.disablePermissions(permissions[i].children);
        }
      }
    },
    enableAllPermission(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        permissions[i].keywords.forEach(keyword => {
          keyword.disabled = false;
        });
        if (permissions[i].children.length !== 0) {
          this.disablePermissions(permissions[i].children);
        }
      }
    },
    addValuesToPermissions(permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].children.length !== 0)
          this.addValuesToPermissions(permissions[i].children);
        permissions[i].keywords.forEach(keyword => {
          keyword.disabled = false;
          keyword.checked = false;
          keyword.childrenChecked = false;
        });
      }
      return permissions;
    },
    parentRoleSelectionChanged(value) {
      this.parentRoleID = value;
      if (this.parentRoleID !== -1) {
        SystemRolesService.getPermissionsByRoleID(this.parentRoleID).then(innerResponse => {
          this.parentRolesPermissions = innerResponse.data.permissions;
          this.disablePermissions(this.permissions);
        });
      } else {
        this.enableAllPermission(this.permissions);
      }
    },
    selectionChanged(value) {
      this.selectedSystemRole = value;
    },
    manageKeyListener() {
      keyListenerManger.editModeAndSaveShortCut(
        this,
        document,
        "editModeAndSaveShortCutListener",
        "boundEditModeAndSaveShortCutListener",
        "editMode",
        "getRoleViewData",
        "saveChanges"
      );
    }
  },
  watch: {
    selectedSystemRole: {
      handler() {
        this.getRoleViewData();
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.boundEditModeAndSaveShortCutListener);
  },
  created() {
    this.roleViewLoading = true;
    this.roleViewLoadingText = "loading roles";

    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.roleView, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          if (this.getUserPermissions.includes("write_System_User Role Administration_Role")) {
            this.manageKeyListener();
          }
          this.fetchAllSystemRoles();

          if (this.$route.params.id) {
            this.selectedSystemRole = parseInt(this.$route.params.id, 10);
          }
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  }
};
</script>

<style scoped></style>
